.content-box {
  background-color: #fff;
  margin-top: 5px;
  padding: 20px;
  border-radius: 5px;
  word-wrap: break-word;

  img {
    width: 100%;
    height: 100%;
  }
}

.welcome_txt {
  color: #707070;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 0px;
}

.balance {
  color: #707070;
  // display: inline;
  font-size: 12px;
}

.balance .usdt {
  background-image: url(../../Assets/i_usdt_green.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 12px;
  width: 13px;
  display: inline-block;
}

.easy_link {
  font-size: 10px;
  word-break: break-all;
  display: inline-block;
  margin-right: 3rem;
  color: #007bff;
}

.i_copy2 {
  background-image: url(../../Assets/i_copy.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.walletAddress-box {
  display: flex;
  align-items: center;
}

// .qrCode-box {
//     color: #007bff;
//     fill: red !important;
//     filter: invert(100%);
//     background-color: red;
//     height: 1px;
// }

// .qrCode-img {
//     color: #007bff;
//     fill: red !important;
//     filter: invert(100%);
//     background-color: red;
//     height: 1px;

// }
