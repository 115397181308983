.header {
  display: grid;
  grid-template-columns: 5% repeat(4, 20% 30% 20% 15%) 5%;
  border-bottom: none;
  padding: 2rem 10px;

  @media only screen and(max-width:767px) {
    padding: 1rem 10px;
    padding-top: 1.5rem;
  }

  .title[data-show="show-form"] {
    grid-column: 1 / 4;
  }

  .title {
    grid-column: 2 / 5;

    @media only screen and(max-width: 767px) {
      font-size: 2rem;
      grid-column: 1 / 5;
    }
  }

  .close[data-show="show-form"] {
    cursor: pointer;
    width: 50% !important;
    text-align: right;
  }

  .close {
    grid-column: 6 / 7;
    cursor: pointer;

    @media only screen and(max-width: 767px) {
      width: 50% !important;
      text-align: right;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
