// .home_btn {
//   background-color: #fff;
//   border-radius: 5px;
//   text-align: center;
//   color: #242e47;
//   padding: 20px;
//   display: inline-block;
//   font-size: 16px;
//   letter-spacing: 2px;
//   // width: 90%;
//   max-width: 40rem;
//   margin-bottom: 2rem;
//   transition: 0.3s;
//   -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
//   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);

//   @media only screen and(max-width: 430px) {
//     padding: 0;
//   }
// }

// .home_btn:hover {
//   color: #3f80fa;
//   text-decoration: none;
//   -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
//   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
// }

.home_btn {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  color: #242e47;
  padding: 20px;
  display: block;
  font-size: 16px;
  letter-spacing: 2px;
  // width: 90% !important;
  max-width: 400px;
  margin-bottom: 20px;
  transition: 0.3s;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
}

.home_btn:hover {
  color: #3f80fa;
  text-decoration: none;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

.home_btn .trade {
  background-image: url(../../Assets/i_usdt_blue.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 40px auto 20px;
  height: 60px;
  width: 60px;
  display: block;
}

.home_btn .i_01 {
  background-image: url(../../Assets/i_01.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 40px auto 20px;
  height: 60px;
  width: 60px;
  display: block;
}

.home_btn .i_wallet {
  background-image: url(../../Assets/i_wallet.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 40px auto 20px;
  height: 60px;
  width: 60px;
  display: block;
}

.home_btn .i_trans {
  background-image: url(../../Assets/i_trans.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 40px auto 20px;
  height: 60px;
  width: 60px;
  display: block;
}

.welcome_txt {
  // color: #707070;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 0px;
  color: #fff !important;
}

@media only screen and(max-width: 550px) {
  .home_btn {
    padding: 1px;
    display: block;
    font-size: 16px;
    letter-spacing: 2px;
    width: 100%;
  }
}
