.authBg {
}

.user-auth {
  background-image: url(../../Assets/easy_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 150vh;
  padding: 0px 8px;
  padding-top: 10rem;
  h4 {
    font-size: 2.5rem;
  }

  @media only screen and(max-width: 500px) {
  }

  @media screen and (max-width: 1280px) and (max-height: 800px) {
    padding-bottom: 20rem;
    height: 100%;
  }
}

.user-auth[host="88U"] {
  background-image: url(../../Assets/88u/88u_bg.png);
}

.form-nav {
  margin: 2rem 0 2rem 0;
  padding: 2rem;
}

.form-link {
  display: inline-block;
  text-align: center;
  padding-bottom: 17px;
  font-size: 17px;
  color: #707070;
  border-bottom: 1px solid rgb(206, 201, 201);
  width: 50%;
}

.isActive {
  border-bottom: 2px solid #3f80fa;
  width: 50%;
  color: #3f80fa;
}

label {
  font-size: 12px;
}
