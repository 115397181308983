.container {
  width: 38rem;
  height: 55rem;
  background-color: #f2f2f2;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 999;
  border-radius: 10px;
  overflow: hidden;
  position: fixed;
  bottom: 10rem;
  right: 20px;
  display: none;
  transition: all 1s ease;

  @media only screen and(max-width: 557px) {
  width: 85vw;
  top: 10px;
  height: 80%;

  }

  .header {
    height: 10%;
    width: 100%;
    background-color: #007beb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    span {
      color: white;
    }

    img {
      width: 3rem;
      height: 3rem;
    }

    .close {
      cursor: pointer;
    }
  }
  .body {
    height: 75%;
    width: 100%;
    overflow-y: scroll;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;

    .message-box {
      margin-bottom: 1rem;
    }
  }

  .action {
    width: 100%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid rgb(189, 183, 183);
    padding: 8px 0;

    button {
      border: none;
    }

    .input {
      background-color: #f2f2f2;
    }

    .send {
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
    }

    .upload {
      width: 1.5rem;
      height: 2.5rem;
      cursor: pointer;
    }
  }
}
