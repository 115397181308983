.successRegBox {
    max-width: 60%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.successRegText {
    color: #3E80F9;
    font-weight: bold;

}

.registerLink {
    width: 100%;
}