.container {
  max-width: 1140px;
}

.landingBlock {
  display: flex;
  width: 1200px;
  margin-top: 3rem;

  @media only screen and(max-width: 1280px) {
    width: 1080px;
  }

  @media only screen and(max-width: 1100px) {
    width: 1030px;
  }

  @media only screen and(max-width: 1050px) {
    width: 1000px;
  }

  @media only screen and(max-width: 1010px) {
    width: 940px;
  }

  @media only screen and(max-width: 950px) {
    width: 900px;
  }

  @media only screen and(max-width: 905px) {
    width: 840px;
  }

  @media only screen and(max-width: 850px) {
    width: 800px;
  }

  @media only screen and(max-width: 800px) {
    width: 720px;
  }

  @media only screen and(max-width: 720px) {
    flex-direction: column;
    width: 680px;
    margin: 50px auto;
    text-align: center;
  }

  @media only screen and(max-width: 700px) {
    width: 500px;
  }

  @media only screen and(max-width: 535px) {
    width: 460px;
  }

  @media only screen and(max-width: 460px) {
    width: 400px;
  }

  @media only screen and(max-width: 424px) {
    width: 375px;
  }

  @media only screen and(max-width: 375px) {
    width: 330px;
  }

  @media only screen and(max-width: 330px) {
    width: 300px;
  }

  @media only screen and(max-width: 320px) {
    width: 290px;
  }

  @media only screen and(max-width: 300px) {
    width: 250px;
  }

  .textBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 11rem;

    @media only screen and(max-width: 720px) {
      order: 1;
    }

    .title {
      color: white;
      font-size: 52px;
      line-height: 1.6;
      margin-bottom: 2rem;
      font-weight: bolder;

      @media only screen and(max-width: 1100px) {
        margin-top: 2rem;
        font-size: 48px;
      }

      @media only screen and(max-width: 1050px) {
        font-size: 46px;
      }

      @media only screen and(max-width: 1010px) {
        font-size: 40px;
      }

      @media only screen and(max-width: 905px) {
        font-size: 35px;
      }

      @media only screen and(max-width: 850px) {
        font-size: 32px;
      }

      @media only screen and(max-width: 460px) {
        font-size: 28px;
      }

      @media only screen and(max-width: 424px) {
        font-size: 26px;
      }

      @media only screen and(max-width: 375px) {
        font-size: 23px;
      }

      @media only screen and(max-width: 320px) {
        font-size: 20px;
      }
    }

    .subText {
      color: white;
      font-size: 34px;
      line-height: 1;
      margin-bottom: 2rem;
      font-weight: 900;

      @media only screen and(max-width: 1100px) {
        font-size: 30px;
      }

      @media only screen and(max-width: 1010px) {
        font-size: 25px;
      }

      @media only screen and(max-width: 905px) {
        font-size: 22px;
      }

      @media only screen and(max-width: 850px) {
        font-size: 21px;
      }

      @media only screen and(max-width: 460px) {
        font-size: 18px;
      }

      @media only screen and(max-width: 424px) {
        font-size: 17px;
        margin-bottom: 1rem;
      }

      @media only screen and(max-width: 375px) {
        font-size: 14px;
      }

      @media only screen and(max-width: 320px) {
        font-size: 13px;
      }
    }
  }

  .imgBox {
    @media only screen and(max-width: 414px) {
      text-align: center;
    }
    .phone {
      height: 50vh;

      @media only screen and(max-width: 424px) {
        height: 40vh;
      }
    }
  }
}



.cs-button {
  background-color: red;
  width: 10rem;
  height: 7rem;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  overflow: hidden;
  z-index: 999;

  button {
    width: 100%;
    height: 100%;
    background-color: rgb(69, 69, 216);
    border: none;
    color: white;
    font-size: 3rem;
  }
}
