.form-input {
  padding: 2.6rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.form-select {
  height: 5.2rem;
}

.form-date {
  height: 5.2rem;
  font-size: 1.5rem;
  padding-right: 26px;

  @media only screen and(max-width: 768px) {
    padding-right: 24px;
  }

  @media only screen and(max-width: 500px) {
    padding-right: 20px;
  }

  @media only screen and(max-width: 404px) {
    padding-right: 19px;
  }

  @media only screen and(max-width: 390px) {
    padding-right: 18px;
  }

  @media only screen and(max-width: 360px) {
    padding-right: 16px;
  }

  @media only screen and(max-width: 330px) {
    padding-right: 14px;
  }
}

.form-btn {
  height: 6rem;
  font-size: 2rem;
  margin-top: 3.5rem;
  background-color: #3e80f9;
  border-radius: 5px;

  &:hover {
    background-color: #568ef4;
  }
}

.forget_pw-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.5rem;
  font-size: 1.8rem;
  margin: 2rem 0 2.5rem 0;

  span {
    background-image: url("../../../Assets/i_ask.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    display: block;
    border-radius: 100%;
    margin-right: 1rem;
  }
}

.forget_pw-link {
  &:link,
  &:visited {
    cursor: pointer;
    color: rgb(10, 9, 9);
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
  }
}

.form-text {
  color: red !important;
}

.login-spinner {
  margin-top: 5rem;
}
