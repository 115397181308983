.modal-content {
  border: 5px solid #3f80fa;
  span {
    color: #3f80fa;
    font-size: 3rem;
  }

  .modal-img {
    height: 15rem;
    margin-left: 5.5rem;
  }
}
