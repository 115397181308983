.pay-info {
  background-color: rgba(215, 226, 243, 0.2);
  padding: 10px;
  display: flex;
  align-items: center;

  @media only screen and(max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & > p {
    margin-right: 5rem;
    @media only screen and(max-width: 600px) {
      margin-right: 0;
      margin-top: 1rem;
    }
  }

  & > p > span {
    margin-left: 1rem;
  }
}

.pairBox {
  padding: 1rem;

  @media only screen and(max-width: 500px) {
    padding: 4rem 1rem;
  }
}

.pair-titleBox {
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;

  @media only screen and(max-width: 360px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
}

.pair-textBox {
  font-size: 1.5rem;

  @media only screen and(max-width: 360px) {
  }

  span {
    color: #007bff;
    font-size: 2.5rem;
  }
}

.payTime {
  color: rgb(146, 126, 10);
}

.pairFoot {
  text-align: center;
}

.pairFoot-btn {
  padding: 1rem 6rem;
  margin: 2rem;

  @media only screen and(max-width: 308px) {
    padding: 1rem;
  }
}

// Buy Count
.btn-box {
  text-align: center;

  @media only screen and(max-width: 768px) {
  }
}

.buyCountForm {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;

  @media only screen and(max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.buyCount-input {
  padding: 2.5rem 2rem;
  margin-top: 3rem;
  width: 25rem;
  font-size: 2rem;

  @media only screen and(max-width: 976px) {
    max-width: 30rem;
  }

  @media only screen and(max-width: 330px) {
    max-width: 25rem;
  }

  @media only screen and(max-width: 280px) {
    max-width: 22rem;
  }
}
::placeholder {
  font-size: 2rem;

  @media only screen and(max-width: 767px) {
    font-size: 1.2rem;
  }
}

.buyCount-btn {
  margin: 3rem 0;
  padding: 2rem 15rem;
}

.transaction-twoWay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.twoWay-icon {
  width: 4rem;

  @media only screen and(max-width: 1199px) {
    transform: rotate(90deg);
  }
}

.buyCount-btn {
  margin-left: 0 !important;
}

.btn-box {
  width: 90%;
}

// confirm buy
.confirmBuyContent {
  margin-top: 3rem;
}

.confirmBuyInput {
  font-size: 17px;
  padding: 30px;
}

.confirmBuy-textContent {
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #3f80fa;
  display: flex;
  justify-content: space-between;
}

.confirmBuy-text {
  font-size: 20px;
  font-weight: bold;
  color: #707070;
}

.buyCount-btnBox {
  text-align: center;
}

.buyCount-btn {
  @media only screen and(max-width:767px) {
    display: block;
    width: 100%;
  }

  @media only screen and(max-width:403px) {
    padding: 15px 0;
  }
}
