.chatBox {
  background-color: #fff;
  border-radius: 10px;
  width: 360px;
  height: 500px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0px;
  box-shadow: 3px 4px 12px 5px rgba(0, 0, 0, 0.2);

  @media only screen and(max-width: 414px) and(min-height: 720px) {
    right: 20px;
    top: 70px;
  }

  @media only screen and(max-width: 375px) {
    width: 300px;
    height: 460px;
    top: 55px;
  }

  @media only screen and(max-width: 320px) {
    width: 250px;
    height: 450px;
    top: 55px;
  }
}

.chatBox .top {
  background-color: #3f80fa;
  color: #fff;
  padding: 15px 15px 7px;
  border-radius: 10px 10px 0px 0px;
  word-wrap: break-word;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 60px;
}

.chatBox .top span {
  background-image: url(../../Assets/i_logo.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 12px;
}

.chatBox .top p {
  display: inline;
  position: relative;
  margin-bottom: 2px;
}

.talk {
  height: 76%;
  // overflow: auto;
  overflow-y: scroll;
  padding: 10px;
  z-index: -1;
  position: absolute;
  top: 62px;

  @media only screen and(max-width: 1920px) {
    top: 72px;
    height: 74%;
  }

  @media only screen and(max-width: 375px) {
    top: 81px;
    height: 70%;
  }

  @media only screen and(max-width: 320px) {
    height: 69.5%;
  }
}

.talk_l {
  background-color: #d7e2f3;
  color: #707070;
  border-radius: 5px;
  font-size: 12px;
  padding: 15px 10px;
  width: 70%;
}
.talk_r {
  background-color: #f6f6f6;
  color: #707070;
  border-radius: 5px;
  font-size: 12px;
  padding: 15px 15px;
  width: 70%;
  margin-left: auto;
}

.talk_time {
  color: #707070;
  font-size: 10px;
  margin-bottom: 10px;
}
.a_right {
  text-align: right;
}

.attach_icon {
  background-image: url(../../Assets/attach_icon.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  width: 15px;
  display: inline-block;
  margin-left: 10px;
}

.send_icon {
  background-image: url(../../Assets/send_icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 22px;
  width: 20px;
  display: inline-block;
  background-position: center;
  margin-left: 5px;
}

.chatBox .bottom {
  padding: 10px;
  border-top: 1px solid #d7e2f3;
  position: absolute;
  bottom: 0;
  width: 100%;
}
input.talkBox {
  background-color: transparent;
  margin: 0px;
  padding: 5px;
}
input.talkBox:focus {
  outline-width: 0;
  box-shadow: none;
  border: none;
  outline: none;
}

.soundBtnBox {
  z-index: 99999;
  position: absolute;
  right: 5px;
  top: 5px;
}
