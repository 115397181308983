.form {
  padding: 1rem;
}
.input {
  padding: 2rem 1rem;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: none;

  &::placeholder {
    color: #707070;
    letter-spacing: 5px;
    font-size: 1.5rem;
  }
}
