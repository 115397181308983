.transactionNav {
  @media only screen and(max-width: 380px) {
  }
}

.transactionLink {
  @media only screen and(max-width: 380px) {
    padding: 14px 0 !important;
    width: 6rem !important;
  }
}
